const domain = window.location.hostname;
let typeApp = {}
if (domain === 'localhost') {
  typeApp = {

    type: "orbishigiene",
    api: "https://pedidos.orbishigiene.com/backend/api",
    page_password_reset:
      "https://pedidos.orbishigiene.com/backend/password_reset/",

    configApp: {
      /*
          'formato' => Si es true,  Muestra el selector formato en el detalle del producto, carrito y pedido.
          'stock_availability' => true, muestra los textos de disponibilidad en lugar del stock. Si es TRUE no hace caso de como este configurado el stock.
          'stock' => true, Muestra el campo stock en toda la app.
          'stock_limit' => Si es true, no se podrá añadir más unidades que las del stock. (oculta botón añadir carro)
          'obsolete_limit' => Si es true, no se podrá añadir más unidades que las del stock en los productos obsoletos (oculta botón añadir carro)
          'category_sidebar' => true, Muestra las categorías en el sidebar izquierdo.
          'category_featured' => true, Muestra las categorías destacadas en las categorías del primer nivel y home.
          'catalogo_public' => true, Se puede acceder a la url del catálogo público (/catalogo-publico)
          'client_code' => 'external_id' // Indicamos cual será el código de cliente a mostrar.
          'client_locked' => Si es True se muestra la columna de clientes bloqueados en el listado de clientes.
          'url_home': 'pedidosHome' // url de la home para hacer redirecciones.
          'elastic_search' => Si es true, se activa el buscador Elastic en el catálogo y pedidos.
          'menu_pedidos_albaranes' => Si es true mostramos la opción en el menú "Pedidos/Albaranes"
          'menu_pedidos_importacion' => Si es true mostramos la opción en el menú "Pedidos/Importación"
          'menu_pedidos_home' => Si es true mostramos en el menú "Pedidos".
          'menu_pedidos_validar' => Si es true mostramos la opción en el menú "Pedidos/Validar Pedidos"
          'menu_ofertas_validar' => Si es true mostramos la opción en el menú "Ofertas/Validar Ofertas"
          'extra_images' => Si es true primero mostramos la imagen del cliente y sino la de Orbita.
          'popup_aviso' => Si es true se muestra un mensaje de aviso en el centro de la web, solo una vez
          'is_outlet' => Si es true se muestra el selector/filtro con está opción.
          'is_eco' => Si es true se muestra el selector/filtro con está opción.
          'is_covid' => Si es true se muestra el selector/filtro con está opción.
          'id_ga' => Código de google analytics.
          'id_ga_public' => código de google analytics para páginas públicas
          'cart_und' => si es true se mmostraran en la pelotitas las unidades del producto.
          'decimals_num_max' => Número máximo de decimales en Monedas (precios, descuentos, etc...)
          'favorite' => Si es true muestra favoritos en la web
          'sort_cart_drag' => Si es true se puede ordenar el carro arrastrando.
          'sitekey_recaptcha' => sitekey de google para utilizar "recaptcha V2"
      */
      formato: false,
      stock_availability: false,
      stock: false,
      stock_limit: false,
      obsolete_limit: true,
      category_featured: true,
      catalogo_public: true,
      client_code: "external_id",
      client_locked: false,
      url_home: "catalogoList",
      elastic_search: false,
      menu_pedidos_albaranes: true,
      menu_pedidos_facturas: true,
      menu_pedidos_importacion: true,
      menu_pedidos_validar: false,
      menu_pedidos_home: true,
      extra_images: false,
      popup_aviso: false,
      is_outlet: false,
      is_eco: false,
      is_covid: false,
      id_ga: "UA-164132826-1",
      id_ga_public: "UA-164132826-2",
      cart_und: true,
      decimals_num_max: 2,
      favorite: false,
      sort_cart_drag: false,
      sitekey_recaptcha: "6LeW9toUAAAAAM2VjAYKzjBF9Ejuq0Xc_0V1m7DY",
      catalog_generator: false,
    }
  }
} else {
  typeApp = {
    type: "orbishigiene",
    'api': '//' + domain + '/backend/api',
    'page_password_reset': '//' + domain + '/backend/password_reset/',
    configApp: {
      formato: false,
      stock_availability: false,
      stock: false,
      stock_limit: false,
      obsolete_limit: true,
      category_featured: true,
      catalogo_public: true,
      client_code: "external_id",
      client_locked: false,
      url_home: "catalogoList",
      elastic_search: false,
      menu_pedidos_albaranes: true,
      menu_pedidos_facturas: true,
      menu_pedidos_importacion: true,
      menu_pedidos_validar: false,
      menu_pedidos_home: true,
      extra_images: false,
      popup_aviso: false,
      is_outlet: false,
      is_eco: false,
      is_covid: false,
      id_ga: "UA-164132826-1",
      id_ga_public: "UA-164132826-2",
      cart_und: true,
      decimals_num_max: 2,
      favorite: false,
      sort_cart_drag: false,
      sitekey_recaptcha: "6LeW9toUAAAAAM2VjAYKzjBF9Ejuq0Xc_0V1m7DY",
      catalog_generator: false,
    }
  }
}

let userLocale = navigator.languages
  ? navigator.languages[0]
  : navigator.language || navigator.userLanguage;

// Polyfill startsWith
if (!String.prototype.startsWith) {
  String.prototype.startsWith = function (stringBuscada, posicion) {
    posicion = posicion || 0;
    return this.indexOf(stringBuscada, posicion) === posicion;
  };
}
userLocale = userLocale.startsWith("es-") ? "es" : userLocale;

let language = "";
switch (userLocale) {
  case "es":
  case "ca":
  case "gl":
  case "eu":
    language = "es";
    break;
  case "pt":
    language = "pt";
    break;
  default:
    language = "en";
}
// console.log(`Idioma del navegador: ${userLocale}, language Current: ${language}`)

const dashboardConfig = {
  tooltips: {
    enabled: true,
    displayColors: false,
    backgroundColor: "#555",
    bodyFontSize: 12,
    bodyFontStyle: "normal",
    bodyFontColor: "#fff",
    cornerRadius: 4,
    // 'bodySpacing': 100,
    xPadding: 14,
    yPadding: 10,
    // 'caretSize': 100, tamaño flecha
    caretPadding: 10, // distancia del tooltip al punto
    callbacks: {
      title: function (tooltipItem, data) {
        return "";
      },
      label: function (tooltipItem, data) {
        // console.log(tooltipItem,'//', data);
        // console.log(tooltipItem.xLabel);
        let value = tooltipItem.yLabel;
        let formatData = Number(value).toLocaleString("es-ES", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        });
        return formatData;
      },
    },
  },
};

// console.log(`domain: ${domain}, `, typeApp[domain])

const getFeatureConfig = (feature) => {
  return JSON.parse(window.localStorage.getItem(`TSM_FEATURE--${feature}`));
};

window.enableFeature = (feature) => {
  window.localStorage.setItem(`TSM_FEATURE--${feature}`, "true");
  window.location.reload();
};

window.disableFeature = (feature) => {
  window.localStorage.removeItem(`TSM_FEATURE--${feature}`);
  window.location.reload();
};

const isEnabledDomainForCarbonFootprint = () => {
  return ['prisma', 'maripol'].includes(typeApp.type)
}

const featureFlags = {
  carbonFootprint: true,
  carbonFootprintSideBox: true,
  infoSoste: true,
};

export default {
  featureFlags,
  domain: domain,
  type: typeApp.type,
  api: typeApp.api,
  page_password_reset: typeApp.page_password_reset,
  dashboardConfig: dashboardConfig,
  language: language,
  configApp: typeApp.configApp,
};
