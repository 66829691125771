<template lang="pug">
  .CartNotify(:class="{'show':showRoll}")
      .roll-head
        strong
          translate Producto añadido al carro
        button.btn-close( @click="closeRoll" ) X
      .roll-item
          span.img-cn(:class="{'type-outlet': item.is_outlet, 'type-eco': item.is_eco}" )
            img(:src="checkEmptyImg((item.image_url))")
            small(v-if="item.is_eco") #[translate Eco]
            small(v-if="item.is_outlet") #[translate Outlet]
          div.info-cn
            h3 {{item.name}}
            div
              ul
                li #[translate Prov. ]:
                  small &nbsp;{{supplierName(item.supplier)}}
                li(v-if="item.client_reference") #[translate ID Cliente] 
                  small &nbsp; {{ item.client_reference }}
                li #[translate Código]:
                  small &nbsp;{{checkEmpty(item.external_id)}}
                li #[translate Cantidades]: {{add.quantity}}
              p.Precio-box
                span.pvp(:class="{'special':specialPriceType, 'no-stock': !item.stock, 'obsolete': item.obsolete}") {{ total | format-money}} {{item.currency_symbol}}
      .roll-total
        button.btn.btn-orange(@click="goOrden()")
          translate Ver carro
</template>
<script>
import {mapGetters} from 'vuex'
export default {
  name: 'CatalogCartNotify',
  props: ['add', 'type'],
  data () {
    return {
      showRoll: false,
      specialPriceType: false
    }
  },
  created () {
    // console.log(this.type + '--->', this.add)
  },
  computed: {
    ...mapGetters([
      'role'
    ]),
    isTypeApp () {
      // console.log(`--- typeApp: ${this.$config.type}`)
      return this.$config.type
    },
    item () {
      if (this.add) {
        return this.add.product
      }

      return {}
    },
    price () {
      let precio = this.item.price
      if (this.item.special_price !== null) {
        precio = this.item.special_price
        this.specialPriceType = true
        if (this.isTypeApp === 'pedrosa' && this.role === 'client') {
          this.specialPriceType = false
        }
      }
      return precio
    },
    total () {
      const quantity = this.add.quantity
      const formatoActive = this.add.aditional_measurement_unit

      let quantityFormato = 1
      if (formatoActive) {
        this.add.product.aditional_measurement_units.map(item => {
          if (item.id === formatoActive) {
            quantityFormato = item.quantity
          }
        })
      }
      // console.log(`quantity: ${quantity}, formatoActive: ${formatoActive}, quantityFormato: ${quantityFormato}`)
      const totalPrice = this.price * (quantity * quantityFormato)
      return totalPrice
    }
  },
  methods: {
    openRoll () {
      this.showRoll = true
    },
    closeRoll () {
      this.showRoll = false
    },
    supplierName (item) {
      if (item) {
        return item.name
      }

      return '-'
    },
    checkEmptyImg (v) {
      return (v === null || v === '') ? require('@/assets/img/no_disponible.png') : v
    },
    checkEmpty (v) {
      return (!v) ? '-' : v
    },
    goOrden () {
      // console.log(`type: ${this.type}`)
      if (this.type === 'lead') {
        // console.log(`Carrito type=lead`)
        this.$router.push({'name': 'leadOfertaCartList'})
      } else {
        this.$router.push({'name': 'cartList'})
      }
    }
  },
  watch: {
    add () {
      // Recibimos el nuevo producto añadido para mostrarlo.
      // console.log('add product: ', this.add)
      // console.log(`Cart Notify -> id product: ${this.add.idProduct}, quantity: ${this.add.quantity} , item: `, this.add.product)
      if (!this.add.hide) {
        if (this.add.product) {
          // sacamos la notificación
          this.openRoll()
          window.setTimeout(() => this.closeRoll(), 4000)
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .CartNotify{
    position:fixed;
    z-index: 12;
    bottom:10px;
    right: -800px;
    width:100%;
    max-width:324px;
    background-color: $white;
    border:1px solid #979797;
    @include roundAll(2);
    @include transition(0.6);
    @include opacity(0);
    @include box-shadow(all, 15px, rgba($black, 0.1) );

    @include breakpoint(large){
      max-width:560px;
    }

    &.show{
      right: 80px;
      @include opacity(1);
    }
  }

  .roll-head{
    padding: 18px 30px;
    border-bottom: 1px solid $grey-border;

    .btn-close{
      position:absolute;
      top: 14px;
      right: 14px;
      font-weight: $medium;
      font-size: 16px;
      border:none;
      background-color: transparent;
      color: $blue-light;
      @include transition();

      &:hover, &:focus{
        color: $blue;
      }
    }
  }

  .roll-item{
    position:relative;
    border-bottom: 1px solid $grey-border;
    padding-left: 100px;
    padding-right:20px;
    padding-bottom:18px;
    margin-top: 10px;
    margin-bottom:20px;
    min-height: 110px;

    @include breakpoint(large){
      padding-left: 150px;
      padding-right:30px;
    }

    &:last-child{
      margin-bottom:0px;
      border-bottom: none;
    }

    h3{
      font-size:14px;
      color: $blue-light;
      font-weight: $regular;
      text-transform: uppercase;
      margin-top:0;
      margin-bottom:6px;
    }

    ul{
      margin:0;
      padding:0;
      list-style: none;
    }

    li{
      font-size: 12px;
      color: #34373e;

      small{
        font-size: 12px;
        font-weight: $light;
      }
    }
  }

  .img-cn{
    position:absolute;
    top:0px;
    left:5px;
    width: 100px;
    height: 100px;
    border:1px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    @include breakpoint(large){
      left:30px;
    }

    img{
      max-width: 100%;
      max-height:94px;
    }

    & > small{
      position: absolute;
      z-index: 9;
      top:0;
      left:0;
      display: block;
      padding: 4px;
      color: $white;
      text-transform: uppercase;
      font-size:10px;
    }

    &.type-new{
      border-color: rgba(#8D22A7, .77);

      & > small{
        background-color: #4467C8;
      }
    }

    &.type-eco{
      border-color: #9CE16D;

      & > small{
        background-color: #9CE16D;
      }
    }

    &.type-outlet{
      border-color: #f0bd4e;

      & > small{
        background-color: #f0bd4e;
      }
    }
  }

  .info-cn{
    position:relative;
    & > div{
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
    }
  }

  .Precio-box{
    margin:0;
  }

  .roll-total{
    position: relative;
    padding:16px 30px 16px;
    font-size: 14px;
    color: #494f58;
    background-color: $grey-bg;
    text-align: right;

    .btn-action{
      /* position:absolute;
      top:28px;
      right:30px; */
    }

  }
</style>
