<template lang="pug">
  .banner-sidebar
    
    div(v-if="typeEcom === 'orbishigiene'")
      <div class="adplugg-tag" data-adplugg-zone="lateral1_orbis_c_cerrado"></div>
      <div class="adplugg-tag" data-adplugg-zone="lateral2_orbis_c_cerrado"></div>
      <div class="adplugg-tag" data-adplugg-zone="lateral3_orbis_c_cerrado"></div>
      <div class="adplugg-tag" data-adplugg-zone="cuadrado_1_lateral_orbis"></div>
      <div class="adplugg-tag" data-adplugg-zone="cuadrado_2_lateral_orbis"></div>
      //a(href="#" @click="setCategory('337-64')")
        img(:src="img.banner1" width="250")
      //a(href="#" @click="setCategory('337-11-13')")
        img(:src="img.banner2" width="250")
      //a(href="#" @click="setCategory('337-252-253')")
        img(:src="img.banner3" width="250")
      //a( v-if="$route.name !== 'catalogoListPublic'" href="/catalogo/product/22791-cj6x1l-tork-jabon-en-espuma-lavado-manos-claro")
        img(:src="img.comprar" width="250")
      //a(v-if="$route.name == 'catalogoListPublic'" href="Newsletter General_Jabones Tork.pdf" target="_blank")
        img(:src="img.masinfo" width="250")
</template>

<script>
export default {
  name: 'BannerSidebar',
  props: [],
  data () {
    return {
      img: {
        banner1: require('@/assets/img/banner/banner_col_250x358.jpg'),
        banner2: require('@/assets/img/banner/banner_col_250x208.jpg'),
        banner3: require('@/assets/img/banner/banner_col_250x300.jpg'),
        comprar: require('@/assets/img/banner/comprar.jpg'),
        masinfo: require('@/assets/img/banner/masinfo.jpg')
      }
    }
  },
  computed: {
    typeEcom () {
      // console.log(`--- typeApp: ${this.$config.type}`)
      return this.$config.type
    }
  },
  mounted () {
    const ref = this
    setTimeout(() => {
      window.$('.banner-sidebar a[href*="#"]').on({
        click: function (e) {
          e.preventDefault(e)
          const url = window.$(this).attr('href').replace('#', '')
          // console.log('-----' + url)
          ref.setCategory(url)
        }
      })
    }, 2000)
  },
  methods: {
    setCategory (v) {
      this.$emit('toSetCategory', v)
    }
  }
}
</script>

<style lang="scss" scoped>
.banner-sidebar{
  p{
    text-align: center;
    padding-top: 12px;
    margin: 0;
  }

  a, .adplugg-tag{
    display: block;
    width: 250px;
    margin: 0 auto 24px;

  }
}
</style>
