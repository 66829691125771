import 'es6-promise/auto'

import Vue from 'vue'

// Tailwind
import './assets/tailwind.css'

// Config
import Configuration from '@/plugin/configuration'
import appConfig from '@/config-app.js'

// Filters
import formatMoney from '@/filters/format-money'
import formatNumber from '@/filters/format-number'
import slugable from '@/filters/slugable'

// Stores
import stores from '@/store/index'
// importamos los tipos de mutación de vuex
import * as types from '@/store/mutation-types'
// Rutas
import VueRouter from 'vue-router'
import routes from '@/router/index'

import VueMoment from 'vue-moment'
import App from './App.vue'

// funciones comunes
import SrvCommon from '@/services/common'
import SrvUsu from '@/services/users'

// Idioma
import GetTextPlugin from 'vue-gettext'
import translations from '@/assets/translations/translation.json'

// console.log('--', appConfig)
function urlParam (name) {
  var results = new RegExp('[?&amp;]' + name + '=([^&#]*)').exec(window.location.search)
  if (results == null) {
    return null
  } else {
    return decodeURI(results[1]) || 0
  }
}

Vue.use(GetTextPlugin, {
  availableLanguages: {
    es: 'Español',
    en: 'Inglés',
    pt: 'Portugués'
  },
  defaultLanguage: appConfig.language,
  languageVmMixin: {
    computed: {
      currentKebabCase: function () {
        return this.current.toLowerCase().replace('_', '-')
      }
    }
  },
  translations: translations,
  silent: true
})

Vue.use(Configuration, appConfig)
Vue.use(VueRouter)
Vue.use(formatMoney)
Vue.use(formatNumber)
Vue.use(slugable)
Vue.use(VueMoment)

const router = new VueRouter({
  mode: 'history',
  routes,
  // Control del scroll al hacer history back: https://router.vuejs.org/guide/advanced/scroll-behavior.html#async-scrolling
  scrollBehavior (to, from, savedPosition) {
    // console.log('to: ', to)
    // console.log('from: ', from)
    // console.log('savedPosition: ', savedPosition)
    if (savedPosition) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          // console.log('---------------------', savedPosition)
          // console.log('-----*** para ponerlo en el componente - Router: ', this.$router.options.scrollBehavior())
          resolve(savedPosition)
        }, 2500) // 2500
      })
      // return savedPosition
    } else {
      return { x: 0, y: 10 }
    }
  }
})

router.beforeEach((to, from, next) => {
  // console.log(`----------Router BeforeEach-----------`)
  // console.log(`name: ${to.name}`)
  // Para el scroll se ponga en la posición 0 cada vez cambie de página.
  // window.scrollTo(0, 0)
  // Añadimos una clase de estilo al body según la ruta.
  document.body.className = `body-${to.name}`

  // Comprobamos si la ruta necesita autentificación y si el usuario esta logado
  if (to.meta.Auth && !stores.state.user.isLoggedIn) {
    // Verificamos si viene el token en query string.
    let poToken = urlParam('token')
    let buyerCookie = urlParam('buyer_cookie')
    if (poToken && buyerCookie) {
      // Si viene el token, intentamos hacer el login con ese token.
      if (poToken) {
        window.localStorage.setItem('_token', poToken)
        window.localStorage.setItem('_buyerCookie', buyerCookie)
        stores.dispatch('autoLogin')
      }
    } else {
      // Si no viene el token, mandamos al logueo.
      next({path: '/'})
    }
  }

  // Si el usuario se encuentra logado hacemos verificaciones y mandamos a donde quiere ir.
  // verificamos si el token a caducado
  SrvCommon.verifyToken()
  if (to.name === 'clientList' || to.name === 'leadsList') {
    // Borramos el cliente y el centro  de la store y localStorage si estamos en el listado de clientes.
    stores.dispatch('removeClient')
    stores.dispatch('removeCenter')
    stores.dispatch('removeLead')
  } else if (to.name === 'catalogoList' || to.name === 'ProductSingle' || to.name === 'CatalogoListPublic' || to.name === 'ProductSinglePublic') {
    // si entramos en el catálogo eliminamos el Lead si lo tiene.
    stores.dispatch('removeLead')
  }

  if (stores.state.user.isLoggedIn) {
    // Para tener los datos del usuario siempre que se actualice la página o cambie.
    stores.commit('PENDING') // Para mostrar el preload
    SrvUsu.getDetails()
      .then(res => {
        if (res.status === 200) {
          const user = res.data
          stores.dispatch('setUser', user)
          stores.commit('LOGIN_SUCCESS')
          stores.commit('NOT_PENDING') // Para quitar el preload
        } else {
          // this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
        }
      }, () => {
        // this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
      })
  }

  // Aqui tenemos los datos del cliente lo pasamos a la siguiente ruta.
  if (stores.state.client.isClient) {
    // Para tener los datos disponible en la siguiente pag.
    stores.commit(types.SET_CLIENT)
  }

  // Aqui tenemos los datos del centro lo pasamos a la siguiente ruta.
  if (stores.state.center.isCenter) {
    // Para tener los datos disponible en la siguiente pag.
    stores.commit(types.SET_CENTER)
  }

  // Aqui tenemos los datos del Lead lo pasamos a la siguiente ruta.
  // console.log(`isLead: ${stores.state.lead.isLead}`)
  if (stores.state.lead.isLead) {
    // Para tener los datos disponible en la siguiente pag.
    // console.log('---')
    stores.commit(types.SET_LEAD)
  }

  // Para ir a la página que toque.
  next()
})

new Vue({
  el: '#app',
  store: stores,
  router: router,
  render: h => h(App)
})
