<template lang="pug">
  .banner-search
    div.tw-flex(v-if="typeEcom === 'prisma'")
      // .adplugg-tag(data-adplugg-zone="barra_buscador_izquierda_Prisma")
      // .adplugg-tag(data-adplugg-zone="barra_buscador_derecha_Prisma")
      a(href="#" @click="setCategory('864-511')")
        img(:src="img.banner1")
      a(href="#" @click="setCategory('864-402-491')")
        img(:src="img.banner2")


    div(v-if="typeEcom === 'maripol'")
      // .adplugg-tag(data-adplugg-zone="barra_buscador_izquierda_Maripol")
      // .adplugg-tag(data-adplugg-zone="barra_buscador_derecha_Maripol")
      a(href="#" @click="setCategory('1742-1576')")
        img(:src="img.banner1")
      a(href="#" @click="setCategory('1742-1644-1703')")
        img(:src="img.banner2")

    div.ad_pedrosa(v-if="typeEcom === 'pedrosa'")
      .adplugg-tag(data-adplugg-ad="47967")
      .adplugg-tag(data-adplugg-ad="47971")
      //- a(href="#" @click="setCategory('1580-1381')")
      //-   img(:src="img.banner1")
      //- a(href="#" @click="setCategory('1580-1392-1550')")
      //-   img(:src="img.banner2")

    div(v-if="typeEcom === 'orbishigiene'")
      a(href="#" @click="setCategory('337-286')")
        img(:src="img.banner1")
      a(href="#" @click="setCategory('337-64-86')")
        img(:src="img.banner2")
</template>

<script>
export default {
  name: 'BannerSearch',
  props: [],
  data () {
    return {
      img: {
        banner1: require('@/assets/img/banner/banner_sup_50x270.jpg'),
        banner2: require('@/assets/img/banner/banner_sup_50x170.jpg')
      }
    }
  },
  computed: {
    typeEcom () {
      // console.log(`--- typeApp: ${this.$config.type}`)
      return this.$config.type
    }
  },
  methods: {
    setCategory (v) {
      this.$emit('toSetCategory', v)
    }
  }
}
</script>

<style lang="scss" scoped>
.banner-search{

  p{
    text-align: right;
    margin: 0;
  }

  a{
    display: inline-block;
    &:not(:last-child){
      margin-right: 24px;
    }
  }
  .ad_pedrosa{
    display: flex;
    justify-content: space-around;
  }
}
</style>
