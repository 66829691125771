<template>
  <div class="tsm-finger-print tw-flex tw-flex-col">
    <div
      v-show="loading"
      class="tw-flex tw-justify-center tw-items-center tw-p-20 tw-w-full tw-h-auto tw-flex-1 tw-max-w-[1200px] tw-mx-auto tw-flex tw-px-2 tw-flex-col"
    >
      <loading-spinner></loading-spinner>
    </div>

    <div
      v-if="data"
      :class="{ 'tw-opacity-0': loading, 'tw-opacity-1': !loading }"
      class="tw-w-full tw-h-auto tw-flex-1 tw-max-w-[1200px] tw-mx-auto tw-flex tw-px-2 tw-flex-col"
    >
      <div class="tw-flex tw-mt-8 tw-mb-5">
        <div class="tw-flex-1 tw-flex tw-items-center tw-gap-4">
          <img src="./finger-print-logo.svg" alt="Finger Print Logo" />
          <span
            class="tw-flex tw-items-center tw-gap-2 tw-text-[15px] tw-font-medium tw-mt-4"
            >Huella de Carbono de Alcance 3
            <img
              @mousedown="helpModalState = true"
              class="tw-cursor-pointer"
              src="./help-icon.svg"
              alt="¿Qué es esto?"
          /></span>
        </div>
        <div class="tw-flex tw-gap-5 tw-items-center">
          <button
            v-if="center && data.data"
            @click="getDataInPdf()"
            class="tw-flex tw-items-center tw-gap-6 tw-bg-white tw-rounded tw-pl-4 tw-pr-4 tw-h-9 tw-max-h-9 tw-font-medium tw-text-white tw-border tw-bg-[#7EC161]"
          >
            <span>Descargar certificado</span>
          </button>
          <div
            class="tw-flex tw-items-center tw-gap-6 tw-bg-white tw-rounded tw-font-medium tw-max-h-9 tw-border tw-border-[#64B77F]"
          >
            <date-picker
              class="date-picker"
              v-model="calendar.date"
              :lang="calendar.lang"
              :format="calendar.format"
              type="month"
              :first-day-of-week="calendar.firstDayOfWeek"
              range
              :clearable="calendar.clearable"
              :shortcuts="calendar.shortcuts"
              not-before="2016-01-01"
              :not-after="lastDayPenultimeMonth"
              @confirm="changeCalendar"
              :disabled-time="disableTime"
              confirm
            ></date-picker>
          </div>
        </div>
      </div>

      <div v-if="data.data">
        <div class="tw-grid tw-grid-cols-3 tw-gap-6 tw-mb-6">
          <div
            class="tw-flex tw-flex-col tw-gap-0.5 tw-bg-[#D1E2C9] tw-rounded-md tw-pl-4 tw-pr-4 tw-pt-3 tw-pb-2 tw-border tw-border-[#D1E2C9]"
          >
            <div class="tw-text-[16px] tw-pl-2 tw-pt-2">
              CO<sub class="tw-text-[10px]">2</sub> emisiones
            </div>
            <div class="tw-text-[28px] tw-pl-2 tw-text-[#AC0C72]">
              {{ data["total_co2e"] }} Kg CO<sub class="tw-text-[16px]">2</sub>
            </div>
            <div class="tw-flex tw-gap-2">
              <img
                v-if="data['total_co2e_vs_aa'] > 0"
                src="./arrow-up.svg"
                alt="Arrow Up"
              />
              <img
                v-if="data['total_co2e_vs_aa'] < 0"
                src="./arrow-down.svg"
                alt="Arrow Down"
              />
              <span class="tw-text-[15px]"
                >{{ data["total_co2e_vs_aa"] }}%</span
              >
              <span class="tw-text-[14px]">vs a.a.</span>
            </div>
          </div>

          <div
            class="tw-flex tw-flex-col tw-gap-0.5 tw-bg-white tw-rounded-md tw-pl-4 tw-pr-4 tw-pt-3 tw-pb-2 tw-border tw-border-[#64B77F]"
          >
            <div class="tw-text-[16px] tw-pl-2 tw-pt-2">
              € / Pedido
            </div>
            <div class="tw-text-[28px] tw-pl-2">
              {{ data["cost_by_order"] }} €
            </div>
            <div class="tw-flex tw-gap-2">
              <img
                v-if="data['cost_by_order_vs_aa'] > 0"
                src="./arrow-up.svg"
                alt="Arrow Up"
              />
              <img
                v-if="data['cost_by_order_vs_aa'] < 0"
                src="./arrow-down.svg"
                alt="Arrow Down"
              />
              <span class="tw-text-[15px]"
                >{{ data["cost_by_order_vs_aa"] }}%</span
              >
              <span class="tw-text-[14px]">vs a.a.</span>
            </div>
          </div>

          <div
            class="tw-flex tw-flex-col tw-gap-0.5 tw-bg-white tw-rounded-md tw-pl-4 tw-pr-4 tw-pt-3 tw-pb-2 tw-border tw-border-[#64B77F]"
          >
            <div class="tw-text-[16px] tw-pl-2 tw-pt-2">
              Kg  CO<sub class="tw-text-[10px]">2</sub> / Pedido
            </div>
            <div class="tw-text-[28px] tw-pl-2 tw-text-[#3561C4]">
              {{ data["co2e_by_order"] }} Kg
            </div>
            <div class="tw-flex tw-gap-2">
              <img
                v-if="data['co2e_by_order_vs_aa'] > 0"
                src="./arrow-up.svg"
                alt="Arrow Up"
              />
              <img
                v-if="data['co2e_by_order_vs_aa'] < 0"
                src="./arrow-down.svg"
                alt="Arrow Down"
              />
              <span class="tw-text-[15px]"
                >{{ data["co2e_by_order_vs_aa"] }}%</span
              >
              <span class="tw-text-[14px]">vs a.a.</span>
            </div>
          </div>
        </div>

        <div class="tw-grid tw-grid-cols-3 tw-gap-6 tw-mb-4">
          <div
            class="tw-flex tw-flex-col tw-gap-1.5 tw-bg-white tw-rounded-md tw-px-6 tw-pt-2 tw-pb-1 tw-border tw-border-[#64B77F]"
          >
            <div class="tw-text-[14px] tw-pt-1">Distancia recorrida</div>
            <div class="tw-text-[18px] tw-text-[#3561C4]">
              {{ data["total_distance"] }}Km
            </div>
            <div class="tw-flex tw-gap-2">
              <img
                v-if="data['total_distance_vs_aa'] > 0"
                src="./arrow-up.svg"
                alt="Arrow Up"
              />
              <img
                v-if="data['total_distance_vs_aa'] < 0"
                src="./arrow-down.svg"
                alt="Arrow Down"
              />
              <span class="tw-text-[15px]"
                >{{ data["total_distance_vs_aa"] }}%</span
              >
              <span class="tw-text-[14px]">vs a.a.</span>
            </div>
          </div>

          <div
            class="tw-flex tw-flex-row tw-gap-1 tw-bg-white tw-rounded-md tw-px-6 tw-pt-3 tw-pb-2 tw-border tw-border-[#64B77F]"
          >
            <div class="tw-flex tw-flex-col tw-justify-between tw-flex-1">
              <div class="tw-text-[14px]">
                Para absorber el CO2 producido se necesitan:*
              </div>
              <div class="tw-text-[18px] tw-text-[#7EC161]">
                {{ data["trees"] }} árboles/año
              </div>
            </div>
            <div>
              <img src="./tree-by-year.svg" alt="Trees by year" />
            </div>
          </div>

          <div
            class="tw-flex tw-flex-row tw-justify-between tw-gap-1 tw-bg-white tw-rounded-md tw-px-6 tw-pt-3 tw-pb-3 tw-border tw-border-[#64B77F]"
          >
            <div class="tw-flex tw-flex-col tw-gap-2.5">
              <div>
                <div class="tw-text-[14px]">Total pedidos</div>
                <div class="tw-text-[18px] tw-pl-1 tw-text-[#3561C4]">
                  {{ data["total_orders"] }}
                </div>
              </div>
              <div class="tw-flex tw-gap-2">
                <img
                  v-if="data['total_orders_vs_aa'] > 0"
                  src="./arrow-up.svg"
                  alt="Arrow Up"
                />
                <img
                  v-if="data['total_orders_vs_aa'] < 0"
                  src="./arrow-down.svg"
                  alt="Arrow Down"
                />
                <span class="tw-text-[15px]"
                  >{{ data["total_orders_vs_aa"] }}%</span
                >
                <span class="tw-text-[14px]">vs a.a.</span>
              </div>
            </div>
            <div>
              <div class="tw-text-[14px]">Pedidos mensuales</div>
              <div class="tw-text-[18px] tw-pl-1 tw-text-[#3561C4]">
                {{ data["orders_by_month"] }}
              </div>
            </div>
          </div>
        </div>

        <div class="tw-text-[11px] tw-px-1 tw-mb-6">
          <div>
            Cálculos realizados según la norma DIN 16258 y la guía
            <a
              href="https://www.clecat.org/media/CLECAT_Guide_on_Calculating_GHG_emissions_for_freight_forwarding_and_logistics_services.pdf"
              target="_blank"
              class="tw-underline tw-text-[#00ADFC] tw-cursor-pointer"
              >CECLAT</a
            >
            (European Association for Forwarding, Transport, Logistics and
            Customs Services).
          </div>

          <calc-annotation></calc-annotation>
        </div>

        <div
          class="tw-flex tw-flex-col tw-gap-3 tw-bg-white tw-rounded-md tw-px-10 tw-py-5 tw-mb-10 tw-border tw-border-[#CACED5]"
        >
          <div class="tw-flex tw-items-center tw-pr-32">
            <div class="tw-flex-1 tw-text-[15px] tw-font-medium">Emisiones</div>
            <div class="tw-flex tw-gap-20">
              <div v-if="center" class="tw-flex tw-items-center tw-gap-4">
                <span
                  class="tw-rounded-md tw-w-5 tw-h-2 tw-bg-[#567BCD]"
                ></span>
                <span class="tw-text-[#567BCD] tw-text-[12px]">€ / Pedido</span>
              </div>
              <div class="tw-flex tw-items-center tw-gap-4">
                <span
                  class="tw-rounded-md tw-w-5 tw-h-2 tw-bg-[#AC0C72]"
                ></span>
                <span class="tw-text-[#AC0C72] tw-text-[12px]"
                  >kg CO<sub>2</sub>e</span
                >
              </div>
            </div>
          </div>
          <div>
            <chart :chart-data.sync="chartData" :y-axes="yAxes"></chart>
          </div>
        </div>

        <div
          class="tw-flex tw-flex-col tw-overflow-hidden tw-bg-white tw-rounded-md tw-pt-3 tw-border tw-border-[#CACED5]"
        >
          <div
            class="tw-flex tw-items-center tw-px-6 tw-py-2 tw-border-b-2 tw-border-[#7EC161]"
          >
            <div
              class="tw-flex-1 tw-text-[15px] tw-font-medium tw-text-[#1C2C40]"
            >
              Ranking de Centros
            </div>
          </div>
          <div class="tw-grid tw-grid-cols-2 tw-gap-3 tw-bg-[#EFEFEF]">
            <div class="tw-flex tw-flex-col tw-bg-white">
              <div
                class="tw-p-4 tw-text-[15px] tw-font-medium tw-text-[#7EC161]"
              >
                Menor generación CO<sub>2</sub>
              </div>
              <div>
                <ranking-table :data="data['minor_ranking']" />
              </div>
            </div>
            <div class="tw-flex tw-flex-col tw-bg-white">
              <div
                class="tw-p-4 tw-text-[15px] tw-font-medium tw-text-[#E82C2B]"
              >
                Mayor generación CO<sub>2</sub>
              </div>
              <div>
                <ranking-table :data="data['major_ranking']" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="!data.data"
        class="tw-flex tw-flex-col tw-px-2 tw-py-40 tw-bg-white tw-rounded tw-items-center tw-justify-center tw-gap-2"
      >
        <img class="tw-max-w-[4rem]" src="./error.svg" alt="" />
        <div class="tw-text-base">
          No existen datos para el rango de fechas seleccionado, por favor elija
          otro.
        </div>
      </div>
    </div>

    <help :show.sync="helpModalState"></help>
    <date-picker-alert
      :show.sync="datePickerAlertModalState"
    ></date-picker-alert>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import Chart from "./chart";
import appConfig from "@/config-app";
import RankingTable from "@/components/pages/carbon-footprint/RankingTable";
import Help from "@/components/finger-print-help/Modal.vue";
import CalcAnnotation from "@/components/finger-print/CalcAnnotation";
import API from "./api";
import LoadingSpinner from "./LoadingSpinner.vue";
import DatePickerAlert from "@/components/pages/carbon-footprint/DatePickerAlert";
import Stores from "@/store";

export default {
  name: "Main",
  components: {
    DatePickerAlert,
    LoadingSpinner,
    CalcAnnotation,
    RankingTable,
    Chart,
    DatePicker,
    Help,
  },
  data() {
    return {
      error: false,
      data: undefined,
      loading: true,
      datePickerAlertModalState: false,
      helpModalState: false,
      before: this.$moment()
        .startOf("year")
        .startOf("month")
        .add({ day: 1 })
        .format("YYYY-MM-DD[T]HH:mm:ss"),
      after: this.$moment().subtract(1,'months').endOf('month').format("YYYY-MM-DD[T]HH:mm:ss"),
      calendar: {
        before: this.$moment()
          .startOf("year")
          .startOf("month")
          .add({ day: 1 })
          .format("YYYY-MM-DD[T]HH:mm:ss"),
        after: this.$moment().subtract(1,'months').endOf('month').format("YYYY-MM-DD[T]HH:mm:ss"),
        date: [
          this.$moment()
            .startOf("year")
            .startOf("month")
            .add({ day: 1 })
            .format("YYYY-MM-DD"),
          this.$moment().subtract(1,'months').endOf('month').format("YYYY-MM-DD"),
        ],
        shortcuts: false,
        clearable: false,
        lang: appConfig.language,
        format: "MM/YYYY", // 'MMM YYYY'
        firstDayOfWeek: 1,
      },

      cost: {
        data: [
          "9.2",
          "9.0",
          "9.0",
          "9.1",
          "8.9",
          "8.8",
          "9.1",
          "9.0",
          "8.7",
          "8.9",
          "9.2",
          "4.1",
        ],
        label: "€ / Pedido",
        yAxisID: "y-axis-1",
        fill: false,
        borderColor: "#567BCD",
        borderWidth: 2,
        pointBackgroundColor: "#ffffff",
        pointBorderColor: "#567BCD",
        pointRadius: 5,
        datalabels: { padding: 3, align: "end", anchor: "end" },
      },

      kg: {
        data: [
          2874, 2584, 2194, 2012, 1603, 1559, 1738, 1393, 1578, 1975, 528, 5,
        ],
        label: "kg CO2e",
        yAxisID: "y-axis-2",
        fill: false,
        borderColor: "#AC0C72",
        borderWidth: 2,
        pointBackgroundColor: "#ffffff",
        pointBorderColor: "#AC0C72",
        pointRadius: 5,
        datalabels: { padding: 3, align: "end", anchor: "end" },
      },

      chartData: {
        labels: [
          "Abr",
          "May",
          "Jun",
          "Jul",
          "Ago",
          "Sep",
          "Oct",
          "Nov",
          "Dic",
          "Ene",
          "Feb",
          "Mar",
        ],
        datasets: [],
      },

      yAxes: null
    };
  },
  mounted() {
    this.loadData();
  },
  computed: {
    client: () => Stores.getters.client,
    center: () => Stores.getters.center,
    lastDayPenultimeMonth() {
      return this.$moment ? this.$moment(new Date()).subtract(1,'months').endOf('month') : new Date()
    }
  },
  watch: {
    client: {
      handler(client) {
        if (!client) {
          this.$router.push('/catalogo')
        } else {
          this.loadData();
        }
      },
    },
    center: {
      handler() {
        this.loadData();
      },
    },
  },
  methods: {
    disableTime(date) {
      return (
        this.$moment().add({ year: -1 }).format("YYYY-MM-DD[T]HH:mm:ss") > date
      );
    },
    async getDataInPdf() {
      const response = await API.getCertifiedPDF(this.before, this.after, this.data["total_co2e"])
      if (response.status === 200) {
        var blob = new Blob([response.data], {
          type: response.headers["content-type"],
        })
        const link = document.createElement("a")
        link.href = window.URL.createObjectURL(blob)
        link.download = `co2-certificado-${this.$moment().format("YYYY-MM-DD")}.pdf`
        link.click()
      }
    },
    async loadData() {
      this.loading = true;
      this.error = false;

      const request = await API.getData(this.before, this.after);

      if (request.status === 200) {
        this.data = request.data;
        if (this.data.error) {
          this.error = true;
          this.data = {
            data: false
          };
        } else {
          this.chartData.labels = this.data["graph"]["labels"];
          this.cost.data = this.data["graph"]["costs"];
          this.kg.data = this.data["graph"]["co2e"];
          if (this.client && this.center) {
            this.chartData.datasets = [this.cost, this.kg];
            this.yAxes = [
              {
                type: "linear",
                position: "left",

                ticks: {
                  fontColor: "#567BCD",
                  callback: function (value) {
                    return value + " €";
                  },
                },
                id: "y-axis-1",
              },
              {
                type: "linear",
                position: "right",
                id: "y-axis-2",
                ticks: {
                  fontColor: "#AC0C72",
                  callback: function (value) {
                    return value + " kg";
                  },
                },
                datalabels: {
                  display: false,
                },
                gridLines: {
                  display: false,
                },
              },
            ]
          } else {
            this.chartData.datasets = [this.kg];
            this.yAxes = [
              {
                type: "linear",
                position: "right",
                id: "y-axis-2",
                ticks: {
                  fontColor: "#AC0C72",
                  callback: function (value) {
                    return value + " kg";
                  },
                },
                datalabels: {
                  display: false,
                },
                gridLines: {
                  display: false,
                },
              },
            ]
          }
        }
      } else {
        this.error = true;
        this.data = undefined;
      }

      this.loading = false;
    },
    changeCalendar(value) {
      let aDate = value.toString().split(",");
      if (aDate.length === 2) {
        this.before = this.$moment(aDate[0]).format("YYYY-MM-DD[T]HH:mm:ss");
        this.after = this.$moment(aDate[1]).endOf('month').format("YYYY-MM-DD[T]HH:mm:ss");

        if (this.$moment(aDate[0]).year() !== this.$moment(aDate[1]).year()) {
          this.datePickerAlertModalState = true;
        } else {
          this.loadData();
        }
      }
    },
  },
};
</script>

<style lang="scss">
.tsm-finger-print {
  font-family: "Roboto", Arial, Verdana, serif;

  .date-picker {
    border: none;
    margin: 0;
    width: 150px !important;

    .mx-input-wrapper {
      margin: 0;

      input {
        border: none;
        box-shadow: none;
      }
    }
  }
}

.body-carbonFootprint #main-cn {
  background-color: #efefef;
}
</style>
