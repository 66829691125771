<template lang="pug">
  #EstadisticasArticulos

    .Loader-cn(:class="{'show': apiSend}")
      span.Loader-spinner

    .box-1
      .container
        .row
          .col-sm-12
            h2 #[translate Ranking de artículos]
        .row
          .col-sm-4.c-l
            bk-migas
          .col-sm-8.text-right.c-r
            a.btn.btn-white(v-if="pagination.count" @click="urlDownloadXls")
              img(:src="img.download" width="14")
              translate Descargar xlsx
            span.combo-common(v-if="filterCatList.length > 0")
              label #[translate Categoría]:
              .Select-bk.dropdown
                button.form-control(class="btn btn-default dropdown-toggle" type="button" id="ddCat" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true") {{filterCatItem.name}}
                  i(class="fa fa-angle-down" aria-hidden="true")
                ul(class="dropdown-menu" aria-labelledby="ddCat")
                  li(v-for="(item, key) in filterCatList" :class="{'disabled': filterCatList == item}")
                    span(:value="item.id" @click="changeFilterCat(item)") {{item.name}}
            date-picker(class="calendar-cn" v-model="calendar.date" :lang="calendar.lang" :format="calendar.format" type="month" range :clearable="calendar.clearable" :shortcuts="calendar.shortcuts" not-before="2016-01-01" @input="changeCalendar" confirm)

    .container.box-3
      div
        .row(v-if="pagination.count === 0")
          .col-sm-12
            p #[translate No hay datos para la consulta]
        .row(v-if="pagination.count")
          .col-sm-1.num-cn.text-center
            strong #[translate Nº]
          .col-sm-1.id-cn.text-center
            strong #[translate ID]
          .col-sm-3.nombre-cn
            strong #[translate Artículo]
          .col-sm-1.category-cn
            strong #[translate Categoría]
          .col-sm-1.quantity-cn.text-right
            strong #[translate Cantidad]
              small (#[translate uds].)
          .col-sm-2.compras-cn.text-right
            strong #[translate Compras €]
          .col-sm-1.quantity-aa.text-right
            strong #[translate Cantidad a.a].
          .col-sm-2.compras-aa.text-right
            strong #[translate Compras € a.a].

    .container.box-4
      .row.row-cn(v-for="(item, index) in oData")
        .col-sm-1.num-cn.text-center
          p {{positionRanking(index)}}
        .col-sm-1.id-cn.text-center
          p {{item.external_id}}
        .col-sm-3.nombre-cn
          p {{item.name}}
        .col-sm-1.category-cn
          p {{item.category}}
        .col-sm-1.quantity-cn.text-right
          p {{item.period_purchases_quantity | format-number}}
        .col-sm-2.compras-cn.text-right
          p {{item.period_purchases | format-number}}
        .col-sm-1.quantity-aa.text-right
          p {{item.ppy_purchases_quantity | format-number}}
        .col-sm-2.compras-aa.text-right
          p {{item.ppy_purchases | format-number}}

    .container.pagintation-cn(v-if="pagination.count")
      .row
        .col-sm-12.text-center
          bk-paginate(:page-count="pagination.totalPages"
            :page-range="4"
            :margin-pages="2"
            :click-handler="pagChange"
            :prev-text="pagination.paginationTxt.prev"
            :next-text="pagination.paginationTxt.next"
            :container-class="'pagination'"
            :page-class="'page-item'"
            :initial-page="pagination.currentPage - 1"
            v-show="pagination.totalPages")

</template>
<script>
import appConfig from '@/config-app.js'
import * as types from '@/store/mutation-types'
import BkMigas from '@/components/client/estadisticas/migas'
import DatePicker from 'vue2-datepicker'
import BkPaginate from 'vuejs-paginate'

// importamos servicios estadisticas
import SrvEstadisticas from '@/services/estadisticas'

export default {
  name: 'EstadisticasArticulos',
  components: {BkMigas, DatePicker, BkPaginate},
  data () {
    return {
      'apiSend': false, // lo ponemos en true cuando enviemos peticiones a la API para mostrar el preload.
      'oData': null, // Objeto dónde guardamos los datos de la API
      'pagination': {
        'count': null,
        'totalPages': 0,
        'currentPage': 1,
        'pageSize': 20,
        'paginationTxt': {
          'prev': this.$gettext('Anterior'),
          'next': this.$gettext('Siguiente')
        }
      },
      'img': {
        download: require('@/assets/img/icos/ficha-01.svg')
      }
    }
  },
  created () {
    // console.log('-------', this.dashboardStore.node_name)
    this.getClientPurchasesProduct(this.pagination.currentPage)
  },
  computed: {
    client () {
      return this.$store.getters.client
    },
    migasId () {
      // return  ['Total', 'Barcelona', 'ARGENTONA', '40638']
      return this.$store.state.client.clientDashboardOptions.migasId
    },
    calendar () {
      let before = this.$store.state.client.clientDashboardDate.before
      let after = this.$store.state.client.clientDashboardDate.after
      // console.log(`-----> periodo desde ${after} hasta ${before}`)
      return {
        'before': before,
        'after': after,
        'date': [after, before],
        'shortcuts': false,
        'clearable': false,
        'lang': appConfig.language,
        'format': 'MM/YYYY' // 'MMM YYYY'
      }
    },
    filterCatList () {
      return this.$store.getters.filterCatList
    },
    filterCatItem () {
      let item = this.$store.getters.filterCatItem
      let list = this.$store.getters.filterCatList
      if (item.name === '' && list.length > 0) {
        return list[0]
      }
      return item
    }
  },
  methods: {
    getClientPurchasesProduct (page) {
      this.apiSend = true // mostramos el preload
      // Generamos el menu dle sidebar izquierdo
      SrvEstadisticas.getClientPurchasesProduct(this.migasId, page, this.calendar.before, this.calendar.after, this.filterCatItem.id)
        .then(res => {
          if (res.status === 200) {
            // console.log('------------ toSearch ---------------')
            // console.log('--->Data estadísticas Ranking artículos: ', res.data)
            this.oData = res.data.results
            this.pagination.count = res.data.count
            this.pagination.totalPages = res.data.total_pages
            this.pagination.currentPage = page
          } else {
            // this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          }
          this.apiSend = false // ocultamos el preload
        }, () => {
          // console.log('----->' + error)
          // this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
        })
    },
    changeCalendar (value) {
      let aDate = value.toString().split(',')
      if (aDate.length === 2) {
        let after = this.$moment(aDate[0]).format('YYYY-MM-DD[T]HH:mm:ss')
        let before = this.$moment(aDate[1]).endOf('month').format('YYYY-MM-DD[T]HH:mm:ss')
        this.$store.commit(types.UPDATE_CLIENT_DASHBOARD_DATE, {'after': after, 'before': before})
        // console.log(`${this.calendar.after}, ${this.calendar.before}`)
        this.getClientPurchasesProduct(this.pagination.currentPage)
      }
    },
    changeFilterCat (item) {
      // console.log(`id: ${item.id}, name: ${item.name}`)
      this.$store.dispatch('setFilterCatItem', item)
    },
    pagChange (pageNum) {
      // console.log(`página: ${pageNum}`)
      this.getClientPurchasesProduct(pageNum)
      window.scrollTo(0, 0)
    },
    positionRanking (index) {
      // console.log(`index: ${index}, currentPage: ${this.pagination.currentPage}, pageSize: ${this.pagination.pageSize}`)
      let position = (this.pagination.currentPage === 1) ? index + 1 : (index + 1) + (this.pagination.pageSize * (this.pagination.currentPage - 1))
      return position
    },

    urlDownloadXls () {
      const migas = this.migasId
      let param = ''
      if (migas.length) {
        let migasTmp = migas.slice(1) // Eliminamos el primer item del array "Total"
        param = migasTmp.map((value, key) => `level${key}=${value}`).join('&') // creamos los parámetros del árbol (level0=Barcelona&level1=ARGENTONA&level2=40638).
      }
      var url = `/c_dashboard/client_product_purchases.xlsx/?client=${this.client.id}&page=${this.pagination.currentPage}&before=${this.calendar.before}&after=${this.calendar.after}&category=${this.filterCatItem.id}&${param}`
      this.apiSend = true // mostramos el preload
      var nameSlice = this.client.name.slice(0, 10)
      var nameForXlsx = nameSlice.replaceAll('.', '_')
      var dateBefore = this.$moment(this.calendar.before).format('YYYY-MM-DD')
      var dateAfter = this.$moment(this.calendar.after).format('YYYY-MM-DD')
      var filename = this.client.external_id + '-' + nameForXlsx + '_' + dateAfter + '_' + dateBefore + '.xlsx'
      SrvEstadisticas.getXlsx(url)
        .then(res => {
          if (res.status === 200) {
            var url = window.URL.createObjectURL(res.data)
            var a = document.createElement('a')
            a.href = url
            a.download = filename
            a.click()
            a.remove()
            setTimeout(() => window.URL.revokeObjectURL(url), 100)
          }
          this.apiSend = false
        }, () => {
          // console.log('----->' + error)
          // this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
        })
    }
  },
  watch: {
    migasId () {
      this.getClientPurchasesProduct(this.pagination.currentPage)
    },
    filterCatItem (newItem, oldItem) {
      // console.log(`watch filterCatItem - id: ${newItem.id}`)
      this.getClientPurchasesProduct(this.pagination.currentPage)
    }
  }
}
</script>
<style lang="scss" scoped>
  #EstadisticasArticulos{
    position: relative;
    background-color: $white;
    min-height: 60vh;
    padding: 24px 28px 80px;
  }

  .container{
    padding: 0;
  }

  .box-1{
    position: relative;
    padding-bottom: 10px;

    &:after{
      content: '';
      position: absolute;
      bottom: 0;
      left: -30px;
      right: -30px;
      border-bottom: 1px solid #E8E8E8;
    }

    .row{
      display: flex;
      align-items: center;
    }

    .c-r{
      padding-right: 0;
    }

    h2{
      margin: 0 0 8px;
      color: #1C2C40;
      font-size: 15px;
      font-weight: $medium;
    }

    label{
      font-weight: regular;
    }

    .btn-white{
      display: flex;
      font-size: 13px;
      height: 34px;
      line-height: 34px;
      border-color: #E2E2E2;
      padding: 0 20px;
      margin-right: 14px;
      color: rgba(100, 100, 100, 0.7);
      img{
        margin-right: 6px;
      }
    }
  }

  .box-3, .box-4{
    .num-cn{
      padding-left:0;
      padding-right: 0;
    }

    .id-cn, .category-cn, .quantity-cn, .quantity-aa, .compras-aa, .nombre-cn{
      padding-right: 0;
    }

    .num-cn{
      width: 6%;
    }

    .category-cn{
      width: 11%;
      overflow: hidden;

      p{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 100%;
      }
    }

    .quantity-cn{
      width: 10%;
      small{
          display: inline-block;
          margin: 0 -2px 0 4px;
          font-size: 10px;
          font-weight: $regular;
      }
    }

    .compras-cn{
      width: 14%;
    }

    .quantity-aa{
      width: 11%;
      small{
        display: inline-block;
        margin: 0 -2px 0 4px;
        font-size: 10px;
        font-weight: $regular;
      }
    }
    .compras-aa{
      width: 14%;
    }
  }

  .box-3{
    position: relative;
    margin-top: 20px;
    padding-bottom: 20px;
    font-size: 13px;

    & > div {
      &:after{
        content:'';
        position: absolute;
        bottom: 13px;
        left: -15px;
        width: 951px;
        height: 1px;
        background-color: #4F5A95;
      }
    }

    h4{
      font-weight: normal;
      font-size: 16px;

      strong{
        font-weight: bold;
        color: $blue-light;
        display:block;
        font-size: 24px;
        margin:6px 0 30px;
      }
    }
  }

  .box-4{


    .row-cn{
      &:nth-child(odd){
        background-color: #F6FAFD;
      }

      & > *{
        &:first-child{
          // color: $blue-light;
          font-weight: bold;
        }
      }

      p{
        margin: 10px 0;
      }

      .nombre-cn, .id-cn{
        p{
          font-size: 13px;
        }
      }
    }

  }
</style>
