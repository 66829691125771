<template lang="pug">
  .leads-list-item.container
    .row.box-1
      .col-sm-4(data-toggle="collapse" :data-target="'#leads-'+index")
        p
          span.img-cn
            img(:src="img.derecha" width="8")
          span.name {{item.name}}
      .col-sm-3(data-toggle="collapse" :data-target="'#leads-'+index")
          p.comercial-cn {{checkEmpty(item.sales_person.name)}}
      .col-sm-1(data-toggle="collapse" :data-target="'#leads-'+index")
        p.text-center {{item.external_id}}
      .col-sm-2(data-toggle="collapse" :data-target="'#leads-'+index")
        p.text-center {{numOffers}}
      .col-sm-2(@click="createOferta()")
        p.text-center
          img(:src="img.verLead" width="18")

      .col-sm-2.hidden
        p.text-center
          button.btn.btn-trans(@click="deleteLead()") #[translate Borrar]

    .box-2(:id="'leads-'+index" class="collapse")
      .row.center-cn
        .col-sm-4
          p #[translate Dirección]: #[strong {{checkEmpty(item.address_main)}}]
          p #[translate Localidad]: #[strong {{checkEmpty(item.city)}}]
          p #[translate Provincia]: #[strong {{checkEmpty(item.province.name)}}]
          p #[translate Código Postal]: #[strong {{checkEmpty(item.postal_code)}}]
        .col-sm-6.col-md-5
          p #[translate Persona de contacto]: #[strong {{checkEmpty(item.contact)}}]
          p #[translate Teléfono]: #[strong {{checkEmpty(item.phone)}}]
          p #[translate Correo electrónico]: #[strong {{checkEmpty(item.email)}}]
          p #[translate Comercial]: #[strong {{checkEmpty(item.sales_person.name)}}]
        .col-sm-2.col-md-3(v-if="type_app === 'vario'")
          button(class="btn btn-action") #[translate Edit Lead]

      .row(class="offer-head" v-if="numOffers")
        .col-sm-2.c-1
          p #[translate Fecha]
        .col-sm-1.text-right
          p #[translate Oferta número]
        .col-sm-2.text-right
          p #[translate Total Oferta]
        .col-sm-2
          p &nbsp;
        .col-sm-2
          p &nbsp;
      .row(class="offer-item" v-for="offer in item.offers")
        .col-sm-2
          p {{offer.offer_datetime | moment("DD-MM-YYYY")}}
        .col-sm-1.text-right
          p {{offer.number}}
        .col-sm-2.text-right
          p {{offer.total | format-money}} €
        .col-sm-2
          p
            button(class="btn btn-trans" @click="goOferta(offer)" style="display: flex")
              img(:src="img.verOferta" width="18")
              translate Ver oferta
        .col-sm-2
          p.hidden
            button(class="btn btn-trans" @click="deleteOferta(offer)") #[translate Borrar oferta]
</template>
<script>
export default {
  name: 'LeadsListItem',
  props: ['item', 'index'],
  data () {
    return {
      img: {
        derecha: require('@/assets/img/icos/derecha.svg'),
        verLead: require('@/assets/img/icos/ver-lead.svg'),
        verOferta: require('@/assets/img/icos/ver-oferta.svg')
      }
    }
  },
  computed: {
    numOffers () {
      return this.item.offers.length
    },
    type_app () {
      return this.$config.type
    },
    config_app () {
      // console.log('----++', this.$config.configApp)
      return this.$config.configApp
    }
  },
  created () {
    // console.log(this.item)
  },
  methods: {
    checkEmpty (v) {
      return (v === null || v === '') ? '-' : v
    },
    deleteLead () {
      // TODO: Eliminar lead
      // console.log(`delete Lead: `, this.item)
    },
    goOferta (item) {
      // console.log(`Ir a oferta: `, item)
      this.$router.push({name: 'ofertaSingle', params: {id: item.id}})
    },
    deleteOferta (v) {
      // TODO: Borrar oferta
      // console.log(`Borrar oferta: `, v)
    },
    createOferta () {
      // console.log(`crear oferta ---> id: ${this.item.id}, name: ${this.item.name} `)
      this.$emit('toSetLead', this.item)
    }
  }
}
</script>
<style lang="scss" scoped>
  .leads-list-item{
    color:#494f58;
    padding: 0;
  }

  .box-1{
    @include transition();
    font-size: 14px;
    color: #4D4F5C;
    font-weight: $regular;
    border:1px solid #fff;
    cursor: pointer;
    margin: 0;

    & > *{
      padding-top:12px;
      padding-bottom:8px;
    }

    &:hover, &:focus{
      background-color: rgba(#129FE9, .2);
      border:1px solid #00ADFC;

      .name{
        font-weight: $medium;
      }
    }

    p{
      margin:0;
    }

    .client-code{
      span{
        display: inline-block;
        min-width: 102px;
        text-align: right;
      }
    }

    [aria-expanded="true"]{
        img{
          -moz-transform: rotate(90deg) translate(0px);
          -o-transform: rotate(90deg) translate(0px);
          -ms-transform: rotate(90deg) translate(0px);
          -webkit-transform: rotate(90deg) translate(0px);
          transform: rotate(90deg) translate(0px);
        }
    }
  }
  .name{
    text-transform: uppercase;
    @include ellipsis();
    display: block;
    margin-left:34px;
    @include transition();
  }

  .img-cn{
    position: absolute;
    top:0;
    bottom: 0;
    left: 15px;
    width: 32px;
    height: 22px;
    margin: auto;
    padding-right: 10px;

    img{
      @include transition();
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  }

  .count-cn{
    span{
      display:block;
      margin:0 auto;
      max-width: 90px;
    }
  }

  .box-2{
    padding: 16px 15px 46px 50px;
    background-color: #fff;
    border-bottom: 1px solid #F5F6FA;
    color: #4D4F5C;

    strong{
      font-weight: $regular;
    }

    .row{
      display: flex;
      align-items: flex-end;
      & > *{
        &:last-child{
          text-align: right;

          .btn{
            padding: 6px 24px;
          }
        }
      }
    }

  }

  .offer-head{
    margin-top: 26px;
    padding-top: 18px;
    border-top: 3px solid #CFECFB;
  }

  .offer-item{
    background-color: #FAFBFC;
    margin-bottom: 5px;
    padding-top: 8px;
    padding-bottom: 8px;
    p{
      margin-bottom: 0;
    }

    img{
      margin-right: 8px;
      vertical-align: top;
    }

    .btn{
      font-size: 14px;
    }
  }

</style>
