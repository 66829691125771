<template lang="pug">
  .item-cn.row
        .product-cn.col-sm-4
          picture.img-cn
            img(:src="checkEmptyImg(product.image_url)")
          h3 {{product.name}}

        .col-sm-3
          ul
            // li(v-if="product.supplier" class="prov-cn") #[translate Prov].: {{product.supplier.name}}
            li(v-if="isTypeApp === 'pedrosa'") #[translate Ref.] {{product.external_id}}
            li(v-else) #[translate Código]: {{product.external_id}}
            li(v-if="product.quantity_by_packet") {{product.quantity_by_packet}} #[translate unidades por envase]
            li(v-if="product.packets_by_package") {{product.packets_by_package}} #[translate envases por paquete]

        //.col-sm-2(v-if="isTypeApp !== 'orbishigiene' && isTypeApp !== 'ghgorbis' && isTypeApp !== 'arablau'")
          p.und(:class="{'noStock': !product.stock}") {{product.stock}} #[translate Und].
        .col-sm-2.text-right
          p {{entries.price | format-money}} {{product.currency_symbol}}
        .col-sm-2.text-right
          p {{entries.quantity}}
        .col-sm-1.text-right
          p {{priceTotal | format-money}} {{product.currency_symbol}}

          // p.facturar-cn(v-if="isTypeApp !== 'pedrosa' && isTypeApp !== 'maripol'") #[translate Facturar]
            span.group
              small(:class="{'active on':isFacturable}") #[translate Si]
              small(:class="{'active':!isFacturable}") #[translate No]
</template>
<script>
export default {
  name: 'PedidoSingleItem',
  props: ['entries', 'billable'],
  data () {
    return {
      isFacturable: false,
      imgDefault: require('@/assets/img/no_disponible.png')
    }
  },
  computed: {
    product () {
      return this.entries.product
    },
    priceTotal () {
      // return this.entries.price * this.entries.quantity
      return this.entries.base
    },
    itemFormato () {
      return this.entries.aditional_measurement_unit
    },
    isTypeApp () {
      // console.log(`--- typeApp: ${this.$config.type}`)
      return this.$config.type
    },
    config_app () {
      // console.log('----++', this.$config.configApp)
      return this.$config.configApp
    }
  },
  created () {
    // console.log(`entries:`, this.entries)
    this.isFacturable = this.billable
  },
  methods: {
    checkEmptyImg (v) {
      return (v === null || v === '') ? require('@/assets/img/no_disponible.png') : v
    }
  }
}
</script>
<style lang="scss" scoped>
  .item-cn{
    margin:0;
    padding: 20px 0 30px;
    border-bottom: 1px solid #979797;
    color:#4D4F5C;
  }
  .row{
    display: flex;
    align-items: center;
  }

  .product-cn{
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 100px;
    font-size: 14px;
  }

  .img-cn{
    position:absolute;
    top:0;
    bottom:0;
    left:6px;
    width: 78px;
    min-height:58px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    background-color: $white;

    img{
      max-width: 100%;
      max-height: 54px;
      height:auto;
    }
  }

  h3{
    color:#1C2C40;
    font-size: 13px;
    text-transform: uppercase;
    margin:0;
    font-weight: $regular;
    // @include ellipsis();
  }

  ul{
    list-style: none;
    padding:0;
    font-size: 12px;
  }

  .und{
    font-size:14px;
    color:#61c844;

    &.noStock{
      color:#ff5466;
    }
  }
</style>
