<template lang="pug">
  div
    #InmportacionPedidos(v-if="!importWithCenter")
      .box-search
        .container
          .row
            .col-sm-12
              p #[translate Importar de forma automática un pedido contenido en un fichero Excel predefinido].

      .container.box-2
        .row
          .col-sm-12
            h2 #[translate Importación]

      .container.box-3
        .row
          .col-sm-7.c-l
            form
              p
                label #[translate Importar fichero Excel:]
              div.group-cn
                img(:src="img.excel" width="26")
                p
                  input(type="file" id="f-file" ref="file" accept=".xlsx,.xls" @change="handleFileUpload()")
                  button(@click="submitFile()" class="btn btn-action" type="button" :class="{'btn-send': enviando}")
                    span(v-if="!enviando") #[translate Subir]
                    span(v-if="enviando") #[translate Subiendo]
          .col-sm-5.c-r
            p
              img(:src="img.interro" width="16")
              | #[translate Si estás interesado en conocer más detalles sobre la posibilidad de automatizar el envío de pedidos ]
              // a(href="") #[translate Contacta]
              |  #[translate contacta]
              |  #[translate con nosotros].


      .container.box-4
        .row
          .col-sm-7.msg-ok(v-if="msg === 'ok'")
            p
              img.img-cn(:src="img.success" width="20")
              translate El fichero ha sido importado correctamente y el pedido será procesado.
        .row
          .col-sm-7.msg-ko(v-if="msg === 'ko'")
            p
              img.img-cn(:src="img.warning" width="20")
              translate El fichero no ha podido ser importado, por favor revise su contenido y vuelva a intentarlo.

            ul
              li #[translate Error]:
              li(v-for="item in messageError") {{item}}
    #InmportacionPedidosWithCenter(v-else)
      .box-search
        .container
          .row
            .col-sm-12.other
              p.other #[translate Importar de forma automática un pedido contenido en un fichero predefinido].

      .container.box-2
        .row
          .col-sm-12
            h2 #[translate Importación]

      .container.box-3
        .row
          .col-sm-7.c-l
            form
              p
                label.other #[translate Importar fichero:]
              div.tw-flex.tw-items-center
                img(:src="img.fichero" width="26" class="tw-mr-[30px]")
                label(for="f-file" class="tw-bg-[#1DBFFF] tw-text-white tw-h-[42px] !tw-mb-0 tw-flex tw-items-center tw-px-[26px]") 
                  span Seleccionar fichero
                input(type="text" class="tw-border tw-border-[#EFEFEF] tw-text-[14px] tw-h-[42px] tw-w-[58%] tw-pl-5" placeholder="Ningún archivo seleccionado" readonly 
                :value="file ? file.name : ''")
                input(type="file" class="!tw-hidden" id="f-file" ref="file" @change="handleFileUpload()")
                
          .col-sm-5.c-r
            p
              img(:src="img.interro" width="16")
              | #[translate Si estás interesado en conocer más detalles sobre la posibilidad de automatizar el envío de pedidos ]
              // a(href="") #[translate Contacta]
              |  #[translate contacta]&nbsp;
              |  #[translate con nosotros].
        .row.tw-mt-9.tw-mb-6
          .col-sm-7
            form.tw-border-none
              p
                label.other #[translate Seleccionar un cliente:]
              div.tw-flex.tw-items-center
                img(:src="img.center" width="26" class="tw-mr-[30px]")
                label(class="tw-bg-[#1DBFFF] tw-text-white tw-h-[42px] !tw-mb-0 tw-flex tw-items-center tw-px-[26px]" @click="openModalClient" data-toggle="modal" data-target=".modal-client") #[translate Seleccionar cliente]
                input(type="text" class="tw-border tw-border-[#EFEFEF] text-[14px] tw-h-[42px] tw-w-[58%] tw-pl-5" placeholder="Ningún cliente seleccionado" readonly :value="clientActive ? clientActive.name : ''")
                  
        .row.tw-mt-9.tw-mb-6(:class="{'tw-opacity-50': !clientActive}")
          .col-sm-7
            form.tw-border-none
              p
                label.other #[translate Seleccionar un centro:]
              div.tw-flex.tw-items-center
                img(:src="img.center" width="26" class="tw-mr-[30px]")
                label(class="tw-bg-[#1DBFFF] tw-text-white tw-h-[42px] !tw-mb-0 tw-flex tw-items-center tw-px-[26px]"  @click="openModal" :data-toggle="clientActive ? 'modal' : ''" :data-target="clientActive ? '.modal-center-with-client' : ''" ) #[translate Seleccionar centro]
                input(type="text" class="tw-border tw-border-[#EFEFEF] text-[14px] tw-h-[42px] tw-w-[58%] tw-pl-5" placeholder="Ningún centro seleccionado" readonly :value="centerActive ? centerActive.name : ''")
                  
        .row
          .col-sm-7.c-l.text-center
            button(@click="submitFile()" class="btn btn-action" type="button" :class="{'btn-send': enviando}")
              span(v-if="!enviando") #[translate Subir]
              span(v-if="enviando") #[translate Subiendo]

      .container.box-4
        .row
          .col-sm-7.msg-ok(v-if="msg === 'ok'")
            p
              img.img-cn(:src="img.success" width="20")
              translate El fichero ha sido importado correctamente y el pedido será procesado.
        .row
          .col-sm-7.msg-ko(v-if="msg === 'ko'")
            p
              img.img-cn(:src="img.warning" width="20")
              translate El fichero no ha podido ser importado, por favor revise su contenido y vuelva a intentarlo.

            ul
              li #[translate Error]:
              li(v-for="item in messageError") {{item}}
      bk-modal-center(ref="modalCenterWithClient" class="modal-center-with-client" :idClient="clientActive ? clientActive.id : 0" @setCenterWithClient="setCenterForImport")
      bk-modal-client(ref="modalClient" class="modal-client" @setClient="setClientForImport")
</template>
<script>

import SrvImport from '@/services/importacion'
import BkModalCenter from '@/components/center/Model-contentWithClient.vue'
import BkModalClient from '@/components/client/Model-contentWithClient.vue'
import {mapGetters} from 'vuex'
export default {
  name: 'InmportacionPedidos',
  components: {BkModalCenter, BkModalClient},
  data () {
    return {
      'enviando': false,
      'msg': null,
      'messageError': null,
      'file': null,
      clientActive: null,
      centerActive: null,
      img: {
        interro: require('@/assets/img/icos/interroga.svg'),
        excel: require('@/assets/img/icos/importar-excel.svg'),
        fichero: require('@/assets/img/icos/importar-fichero.svg'),
        success: require('@/assets/img/icos/msg-success.svg'),
        warning: require('@/assets/img/icos/msg-warning.svg'),
        center: require('@/assets/img/icos/center.svg')
      }
    }
  },
  created () {
  },
  computed: {
    ...mapGetters([
      'role'
    ]),
    importWithCenter() {
      if (['admin','sales_person'].includes(this.role)) return true
      return false
    }
  },
  methods: {
    setClientForImport(data){
      this.centerActive = null
      this.clientActive = data
    },
    setCenterForImport(data){
      this.centerActive = data
    },
    openModalClient (e) {
      e.preventDefault();
      this.$refs.modalClient.openModal()
    },
    openModal (e) {
      e.preventDefault();
      if (this.clientActive) {
        console.log('eee')
        this.$refs.modalCenterWithClient.openModal()
      }
    },
    handleFileUpload () {
      this.msg = null
      this.messageError = null
      this.file = this.$refs.file.files[0]
      console.log(this.file)
    },
    submitFile () {
      /* if (this.file > 1024 * 1024) {
        e.preventDefault();
        alert('File too big (> 1MB)');
        return;
      } */

      if (this.file && !this.enviando) {
        this.enviando = true
        // Initialize the form data
        let formData = new FormData()
        // Add the form data we need to submit
        formData.append('file', this.file)
        formData.append('note', '')
        if (this.clientActive) {
          formData.append('client_id', this.clientActive.external_id)
          if (this.centerActive) {
            formData.append('center_id', this.centerActive.external_id)
          }
        }
        SrvImport.setOrderImport(formData)
          .then(res => {
            // console.log(`SrvImport -> `, res)
            if (res.status === 201) {
              // console.log(`Pedido single: `, data)
              this.msg = 'ok'
              this.$store.dispatch('getCart')
            } else {
              this.msg = 'ko'
              this.messageError = this.$gettext('Se ha producido un error. Intentelo más tarde.')
            }
            this.resetForm()
          }, (error) => {
            // console.log('----->', error)
            this.resetForm()
            if (error.data) {
              this.msg = 'ko'
              this.messageError = error.data
            } else {
              this.msg = 'ko'
              // this.messageError = this.$gettext('Se ha producido un error. Intentelo más tarde.')
            }
          })
      }
    },
    resetForm () {
      this.file = null
      document.getElementById('f-file').value = null
      this.enviando = false
    }
  }
}
</script>
<style lang="scss" scoped>
.box-search{
  padding: 20px 0;
  background-color: #EFEFEF;
  p{
    margin: 0;
    font-size: 14px;
    font-weight: 300;
  }
}

.box-2{
  h2{
    font-size: 16px;
    font-weight: $medium;
    margin: 30px 0 40px;
  }
}

.box-3{
  padding: 0 0 60px;
  color: #85868A;

  a{
    color: #1DBFFF;
  }

  form{
    position: relative;
    margin: 0 40px;

    &:after{
        content: '';
        position: absolute;
        top:0;
        bottom: 0;
        right: -46px;
        border-left: 1px solid #707070;
        
    }
    &.tw-border-none{
      &:after{
        content:'';
        border: transparent;
      }
    }

    label{
      font-weight: 300;
      font-size: 16px;
      margin-bottom: 16px;
    }

    input[type=file]{
      display: inline-block;
      width: 100%;
      max-width: 465px;
      padding: 10px;
      line-height: normal;
    }

    .btn{
      line-height: 42px;
      height: 42px;
      padding: 0;
      width: 102px;
      border: 1px solid #E1E1E1;
      background-color: #fff;
      color: $color-text;
      font-size: 14px;
      font-weight: 400;

      &:hover{
        background-color: rgba(#E1E1E1, .5);
      }
    }
  }

  .c-l{
    .group-cn{
        display: flex;
        justify-content: space-between;

        p{
          width: 100%;
          max-width: 570px;
        }
    }
  }

  .c-r{
    font-size: 14px;
    font-weight: 300;

    p{
      position: relative;
      padding:12px 12px 12px 60px;

      img{
        position: absolute;
        top:14px;
        left:26px;
      }
    }
  }
}

.box-4{
  p{
    font-size: 17px;
    font-weight: 400;
  }


  .msg-ok, .msg-ko{
    p{
      position: relative;
      padding:30px 34px 30px 70px;
      margin: 0 -10px 0 26px;
      font-size: 14px;
      font-weight: 300;
    }
    .img-cn{
      position: absolute;
      top: 30px;
      left: 30px;
    }
  }

  .msg-ok{
    p{
      background-color:$color-success;
      color: $white;

    }
  }
  .msg-ko{
    p{
        background-color: $color-warning;
        color: $white;
    }
  }

  ul{
    margin: 40px 0 60px;
    padding: 0 0 0 56px;
    list-style: none;
  }

  li{
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 10px;
    color: #818183;

    &:first-child{
      color: #C08654;
    }
  }
}
</style>
